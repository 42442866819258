import React from "react"

import Container from "./Container"

const videos = [
  { id: "947584250", title: "Holistic Body Shop Testimonial" },
  { id: "969262510", title: "Hanes Testimonial" },
  { id: "947583988", title: "Roberta Devers Testimonial" },
  { id: "947584007", title: "Pam Testimonial" }
]

const VideoTestimonialsSection = () => (
  <div className="relative pb-36">
    {/* gradients */}
    <div className="absolute left-[536px] top-0 aspect-square w-1/2 rounded-full bg-teal opacity-40 blur-[500px]" />
    <div className="absolute left-0 top-[92px] aspect-square w-1/2 rounded-full bg-orange opacity-40 blur-[500px]" />

    <script src="https://player.vimeo.com/api/player.js"></script>
    <Container>
      <div className="mb-16 font-serif text-[40px] font-extrabold leading-[56px]">
        <span className="text-teal">Hear</span>
        <span> what our practitioners are saying</span>
      </div>
      <div className="grid grid-cols-3 justify-between gap-x-[117px] gap-y-[83px] lg:grid-cols-1 lg:gap-20">
        {videos.map((video) => (
          <div key={video.id} style={{ padding: "178.22% 0 0 0", position: "relative" }}>
            <iframe
              loading="lazy"
              src={`https://player.vimeo.com/video/${video.id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title={video.title}
            />
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default VideoTestimonialsSection
