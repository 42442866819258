import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline"
import React, { useState, useRef, useEffect } from "react"

import Container from "./Container"

const faqItems = [
  {
    question: "Is Heal.me HIPAA & PIPEDA Compliant?",
    answer: "Yes. We have industry leading security and are fully compliant."
  },
  {
    question: "Does Heal.me work with group practices and wellness centers?",
    answer:
      "The platform is designed for solo practitioners, but some group practices are getting individual accounts for each practitioner - and that works too!"
  },
  {
    question: "In what countries does Heal.me operate?",
    answer: "Currently US & Canada only"
  },
  {
    question: "What type of practitioners does Heal.me work with?",
    answer:
      "We accept practitioners who are licensed or certified in a holistic healing therapy. Note: we do not accept life coaching, astrology, psychic medium, or tarot services."
  },
  {
    question: "Is Heal.me built for accepting insurance?",
    answer: "No, we are focused on cash practices. We do however support HSA/FSA billing and reimbursements."
  },
  {
    question: "Does Heal.me take any commission on bookings?",
    answer:
      "No. As a practitioner, you keep everything you earn. We have Stripe & Square integrations to accept credit card payments, and payments can also be handled offline with cash, venmo, zelle, etc."
  },
  {
    question: "My practice is all virtual. Will Heal.me work for me?",
    answer:
      "Yes. We work with practitioners who work in-person, virtually, in their clients’ homes, and any combination of those."
  },
  {
    question: "I offer group classes and I host retreats. Does Heal.me have this functionality?",
    answer: "Not yet, but group classes are coming very soon!"
  },
  {
    question: "I’m not very tech savvy. Is Heal.me for me?",
    answer:
      "Yes, Heal.me is extremely easy to use, both for you and for your clients. You’ll get an onboarding call when you join, and we also have a support team available to help with anything you need."
  },
  {
    question: "I already have a website. Will Heal.me integrate with my existing site?",
    answer:
      "Heal.me will replace your current site. You’ll keep your web domain and switch it to your Heal.me site. Heal.me will now be your website, booking platform, and everything you need to manage and grow your practice."
  },
  {
    question: "I work out of my home and I don’t want to advertise my address.",
    answer:
      "That’s ok. You can show a general area in the marketplace, and you can also choose to auto-accept new clients or require your approval first."
  },
  {
    question: "What if I join and change my mind?",
    answer:
      "We have a 30 day refund policy in case it isn’t the right fit. And there’s no long term contract, just a month-to-month membership."
  }
]

const FAQSection = () => {
  const [expanded, setExpanded] = useState({})
  const [animatingItems, setAnimatingItems] = useState({})
  const timeoutRefs = useRef({})

  useEffect(
    () => () => {
      Object.values(timeoutRefs.current).forEach(clearTimeout)
    },
    []
  )

  const handleToggle = (question) => {
    setExpanded((prev) => {
      const newExpanded = { ...prev, [question]: !prev[question] }
      if (!newExpanded[question]) {
        setAnimatingItems((prev) => ({ ...prev, [question]: true }))
        timeoutRefs.current[question] = setTimeout(() => {
          setAnimatingItems((prev) => ({ ...prev, [question]: false }))
        }, 300)
      }
      return newExpanded
    })
  }

  return (
    <div className="relative pb-36 pt-12">
      {/* gradients */}
      <div className="absolute left-[430px] top-[-20px] aspect-square w-1/2 rounded-full bg-gradient-to-b from-[#8ab4ff] to-[#e0faff] opacity-20 blur-[200px]" />
      <div className="absolute left-[482px] top-[133px] aspect-square w-1/2 rounded-full bg-[#0093ff] opacity-20 blur-[200px]" />

      <Container>
        <img
          loading="lazy"
          src="/images/practitioner-landing/graphics/lp-elements/faqs.jpg"
          alt="FAQ"
          className="absolute right-[-86px] top-[-130px] w-[505px] rounded-lg shadow-landingPage lg:static lg:mb-32 lg:w-full lg:shadow-landingPageMobile"
        />
        <div className="flex flex-col gap-10 lg:gap-8">
          <div className="text-[32px] font-extrabold">FAQs</div>
          {faqItems.map((item) => (
            <div key={item.question}>
              <div
                className="flex cursor-pointer items-center gap-1 lg:items-start"
                onClick={() => handleToggle(item.question)}>
                {expanded[item.question] || animatingItems[item.question] ? (
                  <MinusIcon className="h-6 w-6 flex-none text-teal lg:mt-0.5" />
                ) : (
                  <PlusIcon className="h-6 w-6 flex-none text-teal lg:mt-0.5" />
                )}
                <div className="text-[22px] hover:text-teal lg:text-lg">{item.question}</div>
              </div>
              <div
                className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${
                  expanded[item.question] ? "max-h-16 lg:max-h-28" : "max-h-0"
                }`}>
                <p className="ml-[28px] text-gray-dark lg:text-sm">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default FAQSection
